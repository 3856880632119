<template>
  <div class="container balance">
    <PageTitle>Balance</PageTitle>
    <hr />
    <Balance :point="getProfile.point" />
    <Trades />
    <UserTransactions />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapGetters } from "vuex";
/*eslint-enable */
import PageTitle from "@/components/PageTitle.vue";
import Balance from "@/components/balance/Balance.vue";
import Trades from "@/views/toefl/views/Trades.vue";
import UserTransactions from "@/views/toefl/views/UserTransactions.vue";

export default {
  metaInfo() {
    return {
      title: "Balance - " + this.CompanyName
    };
  },

  components: {
    PageTitle,
    Balance,
    Trades,
    UserTransactions
  },
  computed: {
    ...mapGetters("user", ["getProfile"])
  }
};
</script>

<style scoped>
.balance {
  padding-top: 40px;
}
</style>
